import { A, cache, createAsync, useLocation, useParams } from "@solidjs/router";
import {
  Accessor,
  createEffect,
  createMemo,
  createSignal,
  Show,
} from "solid-js";
import { failureIcon, greenCheck } from "~/assets/assets";
import { CloseModalButton, useModal } from "~/components/modal";
import { GiftCard } from "~/components/pluto/gift_card";
import { getShareableGiftLink } from "~/server/apis/client_apis";
import {
  getPlutoGiftDetailsRouteData,
  PlutoGiftDetailsRouteData,
} from "~/server/data/pluto_gift_details_route_data";
import { ThreeDotLoader } from "~/widgets/button";
import { PhosphorIcon } from "~/widgets/icons";
import { DottedLoader } from "~/widgets/loader";

interface PaymentStatusState {
  status: "success" | "failure";
  budget?: number;
}

const getGiftDetailsRouteData$C = cache(
  getPlutoGiftDetailsRouteData,
  "pluto_gift_details"
);

export default function PlutoGiftCardShare() {
  const params = useParams();
  const location = useLocation<PaymentStatusState>();

  const { openModal, closeModal } = useModal()!;

  const [shareLink, setShareLink] = createSignal("");
  const [isLoading, setIsLoading] = createSignal(false);

  const paymentStatus = location.state?.status;

  const routeData: Accessor<PlutoGiftDetailsRouteData | undefined> =
    createAsync(() => getGiftDetailsRouteData$C(params.giftId), {
      deferStream: true,
    });

  const getShareableLink = async () => {
    setIsLoading(true);
    try {
      const response = await getShareableGiftLink(params.giftId);
      if (response.link) {
        setShareLink(response.link);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  if (paymentStatus === "failure") {
    return (
      <div class="relative flex h-full w-full flex-col overflow-y-auto bg-[linear-gradient(180deg,_#350E07_0%,_#000_100%)]  md:w-[800px]">
        <div class="top-2/5 relative flex -translate-y-1/2 flex-col items-center justify-center">
          <img src={failureIcon} alt="failure icon" class="h-11 w-11" />
          <p class="mt-4 text-h3 text-errorDark">Payment failed</p>
          <p class="text-normal text-white">
            Please try again or come back later
          </p>
        </div>
        <div class="absolute bottom-0 flex flex-col items-center justify-center gap-4 p-4">
          <p class="text-center text-normal text-textNormal">
            Any money deducted will be refunded in 3-5 business days
          </p>
          <A
            href="/gifts/new"
            class="flex h-[44px] w-full items-center justify-center rounded-[41px] bg-baseTertiaryMedium px-3"
          >
            <p class="text-buttonMedium font-bold text-textDark">Close</p>
          </A>
        </div>
      </div>
    );
  }

  const shareNowModal = async (copy?: boolean) => {
    await getShareableLink();

    openModal(() => {
      const handleShareClick = async () => {
        if (copy) {
          try {
            await navigator.clipboard.writeText(shareLink());
            console.log("Link copied to clipboard");
          } catch (error) {
            console.log("Failed to copy link:", error);
          }
        } else {
          window.open(shareLink(), "_blank");
        }
        closeModal();
      };

      return (
        <div class="relative p-4 pt-6">
          <div class="absolute right-4 top-4" onClick={closeModal}>
            <CloseModalButton />
          </div>
          <Show when={copy}>
            <div class="flex items-center justify-center gap-1">
              <PhosphorIcon
                name="check"
                fontSize={12}
                class={"text-[#24DD7D]"}
              />
              <p class="text-smallBold uppercase text-successDark">
                link copied to clipboard
              </p>
            </div>
          </Show>
          <p class="pb-5 text-center text-h3 text-white">
            Please make sure the <br /> link goes to the right person
          </p>
          <p class="pb-8 text-center text-normal text-textNormal">
            Anyone with this link can use the gift balance for the included
            experiences.
          </p>
          <button
            onClick={handleShareClick}
            class="flex h-11 w-full items-center justify-center rounded-[41px] bg-baseTertiaryMedium px-3"
          >
            <p class="text-buttonMedium font-bold text-textDark">
              <Show when={copy} fallback={<>Got it, share now</>}>
                Got it
              </Show>
            </p>
          </button>
        </div>
      );
    }, "dark");
  };

  return (
    <div class="flex h-full w-full flex-col overflow-y-auto md:w-[800px]">
      <A
        href={`/gifts/new`}
        class=" absolute left-4 top-4 flex h-10 w-10 items-center justify-center rounded-full border border-basePrimaryMedium bg-[#010101]"
      >
        <PhosphorIcon
          name="x"
          fontSize={20}
          size="bold"
          class={`cursor-pointer text-baseTertiaryLight`}
        />
      </A>
      <Show
        when={routeData()?.giftDetails}
        fallback={<DottedLoader color="#999" />}
      >
        <div class="mb-6 mt-12 flex flex-col items-center justify-center gap-3">
          <img class="h-10 w-10" src={greenCheck} alt="brand card" />
          <div class="text-smallBold uppercase tracking-[1.1px] text-successDark">
            Payment successful
          </div>
          <p class="w-[70%] text-center text-h3 text-white">
            Gift worth ₹{location.state?.budget} is ready to share
          </p>
        </div>
        <div class="mx-auto mb-4">
          <GiftCard
            giftDetails={() => routeData()?.giftDetails!}
            flipOnButton={false}
            flipOnCard={true}
            showActions={false}
            showActionsBg={false}
            isEditable={false}
          />
        </div>
        <div class="mx-4 my-6 flex flex-col gap-4">
          <button
            onClick={() => shareNowModal()}
            class="flex h-[44px] w-full items-center justify-center rounded-[41px] bg-baseTertiaryMedium px-3"
          >
            {isLoading() ? (
              <ThreeDotLoader color="#000" />
            ) : (
              <p class="text-buttonMedium font-bold text-textDark">Share now</p>
            )}
          </button>

          <button
            onClick={() => shareNowModal(true)}
            class="flex h-[44px] w-full items-center justify-center rounded-[41px] border border-baseSecondaryDark px-4"
          >
            <p class="text-buttonMedium text-white">Copy gift link</p>
          </button>
          <button class="">
            <p class="text-mediumBold text-white underline">Share feedback</p>
          </button>
        </div>
      </Show>
    </div>
  );
}
